a, h1, h2, .star-light, section.success, span.skills {
  color: #333333 !important;
}

.text-link {
  background: white;
  border-radius: 2em;
  padding: 0.25em;
  text-decoration-line: underline;
}

.web-link a {
  text-decoration-line: underline;
}

.show-modal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow: scroll;

  .modal-content {
    min-height: 135%;
  }
}

h2 {
  font-size: 2em;
}

.list-inline {
  display: flex;
  flex-direction: column;

  li {
    padding: 1em 0;
  }
}

.navbar-collapse {
  transition: all 1.3s;
  max-height: 0;
  .in {
    min-height: 4em;
  }
}

.hide {
  visibility: hidden;
}

.modal.in {
  background: white;
}